.main {

  &::after {
    content: '';
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }



  &--careers::after {
    background-image: url('../img/bg/careers.png');
  }

  &--terms-conditions::after {
    background-image: url('../img/bg/terms-conditions.png');
  }

  &--privacy-security::after {
    background-image: url('../img/bg/privacy-security.png');
  }

  &--contact-us::after {
    background-image: url('../img/bg/contacts-us.png');
  }

  &__content {
    position: relative;
    z-index: 5;
    padding-bottom: 40px;
    color: $color-default-white;

    @include vp--576 {
      padding-top: 118px;
    }

    @include vp--575 {
      padding-top: 104px;
    }

    &--nop {
      @include vp--576 {
        padding: 0;
      }

      @include vp--575 {
        padding: 0;
      }
    }
  }

  & .content {
    &__title {
      text-align: center;
      font-weight: 700;

      &--mb-40 {
        margin-bottom: 40px;
      }

      @include vp--576 {
        @include font(40px, 49px);
        margin: 0 0 32px 0;
      }

      @include vp--575 {
        @include font(24px, 29.26px);
        margin: 0 0 20px 0;
      }
    }

    &__wrap-link {
      @include vp--576 {
        margin: 0 0 30px 0;
      }

      @include vp--575 {
        margin: 0 0 18px 0;
      }
    }

    &__wrap-item {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 50px;

      @include vp--991 {}

      @include vp--767 {}
    }

    &__wrap-descr {}

    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__link {
      color: $color-default-white;
      @include font(16px, 20px);
      transition: all .2s ease 0s;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover {
        color: #FFFCBD;
      }

      &--terms {
        margin-right: 20px;
        position: relative;

        &:not(:last-child) {
          &::after {
            content: '/';
            position: absolute;
            top: 50%;
            right: -13px;
            transform: translateY(-50%);
            color: inherit;
          }
        }

        &.active {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #fff;
          }
        }
      }
    }

    & .list {
      &--privacy {
        list-style-type: upper-roman;
      }

      & .inner-list {
        list-style-type: lower-alpha;

        &.mod {
          list-style-type: none;
        }

        &-2 {
          list-style-type: disc;
        }
      }

      & li {
        margin: 20px 0;

        &.disk {
          list-style-type: disc;
        }

        &.underline {
          text-decoration: underline;
        }

        & span {
          text-decoration: underline;
          transition: all .2s ease 0s;
          cursor: pointer;

          &:hover {
            color: #FFFCBD;
          }
        }
      }

      & a {
        color: #fff;
        text-decoration: underline;
        transition: all .2s ease 0s;

        &:hover {
          color: #FFFCBD;
        }
      }
    }
  }

  & .item-careers {
    position: relative;
    width: calc(100% / 3 - 20px);
    margin-bottom: 20px;

    &:not(:last-child) {
      @include vp--768 {
        margin-right: 20px;
      }
    }

    @include vp--1023 {
      width: calc(100% / 2 - 20px);
    }

    @include vp--767 {
      width: 100%;
    }

    &:hover {
      .item-careers {
        &__inner {
          border-color: #FFFCBD;
        }

        &__title,
        &__subtitle {
          color: #FFFCBD;
        }
      }
    }

    &__link {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
    }

    &__inner {
      border: 1px solid $color-default-white;
      border-radius: 20px;
      padding: 24px 24px 19px 24px;
      min-height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      pointer-events: none;
      transition: border .2s ease 0s;

      a {
        pointer-events: auto;
      }


      @include vp--767 {
        padding: 14px;
      }
    }

    &__title {
      font-weight: 700;
      @include font(24px, 29.26px);
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      transition: color .2s ease 0s;
    }

    &__subtitle {
      font-weight: 700;
      @include font(18px, 21.94px);
      text-align: center;
      transition: color .2s ease 0s;
    }
  }

  & .swiper-slide-active {
    border: 10px solid red;
  }
}
