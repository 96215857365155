.menu {
  &__toggle {
    display: none;

    @include vp--767 {
      @include button-reset;

      display: block;
      width: 40px;
      height: 40px;
      background-color: transparent;
      transition: opacity .3s ease 0s;

      &.active {
        opacity: 0;
      }
    }
  }

  &__body {
    @include vp--767 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      background-color: rgba(0, 0, 0, 0.85);
      padding: 112px 64px;
      transform: translateX(-100%);
      transition: transform .3s ease 0s;

      &.active {
        transform: translateX(0%);
      }
    }
  }

  &__close {
    display: none;

    @include vp--767 {
      @include button-reset;

      display: block;
      position: absolute;
      top: 32px;
      right: 20px;
      width: 40px;
      height: 40px;
      transform: scale(0);
      transition: transform .3s ease .3s;

      &.active {
        transform: scale(1);
      }
    }
  }

  &__list {
    @include list-reset;

    @include vp--768 {
      display: flex;
      align-items: center;
    }
  }

  &__item {
    @include vp--768 {
      margin-left: 20px;
    }
  }

  &__link {
    color: $color-default-white;
    @include font(16px, 19.5px);
    transition: color .2s ease 0s;

    @include vp--768 {
      padding: 10px 0;
    }

    @include vp--767 {
      display: block;
      padding: 4px 0;
      margin: 4px 0;
    }

    &--active {
      color: #FFFCBD;
    }

    &:hover {
      color: #FFFCBD;
    }
  }
}
