@mixin retina {

  @media (min-resolution: $retina-dpi),
  (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin vp--1919 {
  @media (max-width: #{$vp--1920 - 1}) {
    @content;
  }
}

@mixin vp--1439 {
  @media (max-width: #{$vp--1440 - 1}) {
    @content;
  }
}

@mixin vp--1279 {
  @media (max-width: #{$vp--1280 - 1}) {
    @content;
  }
}

@mixin vp--1023 {
  @media (max-width: #{$vp--1024 - 1}) {
    @content;
  }
}

@mixin vp--991 {
  @media (max-width: #{$vp--992 - 1}) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: #{$vp--768 - 1}) {
    @content;
  }
}

@mixin vp--575 {
  @media (max-width: #{$vp--576 - 1}) {
    @content;
  }
}

@mixin vp--374 {
  @media (max-width: #{$vp--375 - 1}) {
    @content;
  }
}

// Mobile first

@mixin vp--375 {
  @media (min-width: $vp--375) {
    @content;
  }
}

@mixin vp--576 {
  @media (min-width: $vp--576) {
    @content;
  }
}

@mixin vp--768 {
  @media (min-width: $vp--768) {
    @content;
  }
}

@mixin vp--1024 {
  @media (min-width: $vp--1024) {
    @content;
  }
}

@mixin vp--1280 {
  @media (min-width: $vp--1280) {
    @content;
  }
}

@mixin vp--1440 {
  @media (min-width: $vp--1440) {
    @content;
  }
}

@mixin vp--1920 {
  @media (min-width: $vp--1920) {
    @content;
  }
}

// Font

@mixin font($fz, $lh) {
  font-size: $fz;
  line-height: $lh;
}

// Reset

@mixin button-reset {
  padding: 0;
  margin: 0;
  border: none;
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}
