.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #141414;

  @include vp--576 {
    padding-top: 118px;
  }

  @include vp--575 {
    padding-top: 104px;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // filter: blur(20px);

    &>video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;

      @media (max-width: 376px) and (max-height: 668px) {
        top: 0;
        transform: translate(-50%, 0);
      }

      @media (max-width: 321px) {
        top: 0;
        transform: translate(-50%, 0);
      }
    }
  }

  &__wrap-link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-200%);
    z-index: 99999;
    width: 100%;
    height: 5.5%;

    & .link {
      width: auto;
      height: 100%;

      & img {
        height: 100%;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }

    @media (max-width: 991px) {
      height: 5%;

      & .link {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 500px) {
      top: 23%;
      transform: translate(-50%, 0%);
      height: 4.3%;

      & .link {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    @media (max-width: 376px) and (max-height: 668px) {
      top: 29%;
    }

    @media (max-width: 321px) and (max-height: 490px) {
      top: 34%;
    }

    @media (max-width: 321px) {
      top: 23%;
    }
  }

  &__wrap-button {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-190%);
    z-index: 99999;
    width: 100%;

    @media (max-width: 500px) {
      top: 23%;
      transform: translate(-50%, 0%);
    }

    @media (max-width: 376px) and (max-height: 668px) {
      top: 27.5%;
    }

    @media (max-width: 321px) and (max-height: 490px) {
      top: 33%;
    }

    @media (max-width: 321px) {
      top: 23%;
    }

    & .button {
      width: 195px;
      height: 42px;
      background: #fff;
      border-radius: 30px;
      transition: all .2s ease 0s;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 376px) {
        width: 170px;
        height: 35px;
      }

      &__text {
        font-weight: 700;
        color: #EC2E3C;
        @include font(15px, 22px);
        transition: all .2s ease 0s;

        @media (max-width: 376px) {
          font-size: 14px;
        }
      }

      &:hover {
        background-color: #FFFCBD;

        &__text {
          color: #ec2e3bd2;
        }
      }
    }
  }
}
