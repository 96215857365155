.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all .5s ease 0s;

  &.active {
    background: #000;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include vp--576 {
      min-height: 118px;
    }

    @include vp--575 {
      min-height: 104px;
    }
  }

  &__logo {
    @include vp--767 {
      position: relative;
      z-index: 5;
    }

    @include vp--575 {
      & img {
        width: 143.05px;
        height: 40px;
      }
    }
  }
}
