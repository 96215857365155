.container {
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  padding: 0 32px;

  &--big {
    max-width: 1984px;
  }

  @include vp--767 {
    &--p-20 {
      padding: 0 20px;
    }
  }
}
